<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-select
            codeGroupCd="RELATED_LAWS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="relatedLawsCd"
            label="관련법규"
            v-model="searchParam.relatedLawsCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="licensKindTable"
      title="인허가 종류 목록"
      tableId="licensKindTable"
      :columns="grid.columns"
      :data="grid.data"
      :columnSetting="false"
      selection="multiple"
      :editable="editable"
      rowKey="limLicenseKindId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn label="추가" v-if="editable" icon="add" @btnClicked="addrow" />
          <c-btn label="삭제" v-if="editable" icon="remove" @btnClicked="removeRow" />
          <c-btn label="저장" v-if="editable" icon="save" @btnClicked="saveLicenseKind" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'license-kind',
  data() {
    return {
      grid: {
        columns: [],
        data: [],
        height: '362px'
      },
      searchParam: {
        useFlag: 'Y',
        relatedLawsCd: null
      },
      relatedLawsItems: [],
      useFlagItems: [],
      editable: true,
      listUrl: '',
      checkUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      this.$comm.getComboItems('RELATED_LAWS_CD').then(_result => {
        this.grid.columns = [
          {
            name: 'relatedLawsCd',
            field: 'relatedLawsCd',
            label: '관련법규',
            align: 'center',
            style: 'width:350px',
            type: 'select',
            comboItems: _result,
            sortable: false,
          },
          {
            name: 'limLicenseKindName',
            field: 'limLicenseKindName',
            label: '인허가종류명',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            align: 'center',
            type: 'text',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:80px',
            sortable: false,
            type: 'check',
            true: 'Y',
            false: 'N'
          },
        ];
        this.editable = this.$route.meta.editable;
        this.listUrl = selectConfig.sop.lim.lincense.kind.list.url;
        this.saveUrl = transactionConfig.sop.lim.lincense.kind.save.url;
        this.deleteUrl = transactionConfig.sop.lim.lincense.kind.delete.url;
        this.getList();
      });
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addrow() {
      this.grid.data.splice(0, 0, {
        editFlag: 'C',
        limLicenseKindId: uid(),  // 인허가종류 알련번호
        relatedLawsCd: null,  // 관련법규
        limLicenseKindName: '',  // 인허가종류명
        sortOrder: '',  // 정렬순서
        useFlag: 'Y',  // 사용여부
        regUserId: this.$store.getters.user.userId,
      });
    },
    saveLicenseKind() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인', 
            message: '저장하시겠습니까?',  
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getList();
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', 
            message: '변경된 데이터가 없습니다.',
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removeRow() {
      let selectData = this.$refs['licensKindTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인', 
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['licensKindTable'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
